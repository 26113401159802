<template>
  <mobile-screen
    :header="true"
    screen-class="resourceTypes-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resourceType-header icon-hea1">
        <template v-slot:left>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("services", "service-items", componentTitle) }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      v-if="translationFields && translationFields.length"
      ref="translateWordsForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="{ translationFields: [...translationFields] }"
      novalidate
    >
      <div
        style="width: 100%; color: #999999; padding: 10px; padding-bottom: 0px; padding-top: 15px; padding-right: 20px; text-align: right;"
      >
        <span
          style="cursor: pointer;"
          @click="applyDefaultTranslationToAll()"
          >{{
            displayLabelName("global.translations.apply-default-to-all")
          }}</span
        >
      </div>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-for="(lang, idx) in languages.data"
          :key="lang.name"
        >
          <div
            v-for="(field, key) in translationFields[idx].attributes"
            :key="key"
            :class="{
              'has-error': errors[`translationFields[${idx}].attributes.${key}`]
            }"
            class="clebex-item-content-wrapper"
            :style="[key != 'name' ? 'display: none;' : '']"
          >
            <div
              class="error-message"
              v-if="
                key == 'name' &&
                  errors[`translationFields[${idx}].attributes.${key}`]
              "
            >
              {{ errors[`translationFields[${idx}].attributes.${key}`] }}
            </div>
            <div class="clebex-section-input" v-if="key == 'name'">
              <label
                class="clebex-section-input-label"
                :for="`translationFields[${idx}].attributes.${key}`"
              >
                {{ lang.name }}<span v-if="idx === 0">*</span>
              </label>
              <Field
                :id="`translationFields[${idx}].attributes.${key}`"
                :name="`translationFields[${idx}].attributes.${key}`"
                as="input"
                type="text"
                v-on:keyup="setDefaultValue(idx, $event.target.value)"
              />
            </div>
          </div>
        </li>
      </ul>
      <br /><br />
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState } from "vuex";
// import { getLang } from "@/services/http-service";

export default {
  name: "TranslateWords",
  data() {
    return {
      defaultValue: "",
      formKey: 0
    };
  },
  created() {
    if (this.translationFields) {
      this.defaultValue = this.translationFields[0].attributes.name;
    }
  },
  computed: {
    ...mapState("language", ["languages"]),
    ...mapState("service", ["serviceItem", "serviceItemTranslations"]),
    translationFields() {
      return this.serviceItemTranslations;
    }
  },
  methods: {
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      this.$store.commit(
        "service/setServiceItemTranslations",
        [
          ...this.translationFields.map((item, idx) => {
            if (values.translationFields[idx].attributes.name) {
              return {
                locale: item.locale,
                attributes: values.translationFields[idx].attributes
              };
            } else {
              return {
                locale: item.locale,
                attributes:
                  item.attributes.name &&
                  item.attributes.name ===
                    values.translationFields[0].attributes.name
                    ? item.attributes
                    : values.translationFields[0].attributes
              };
            }
          })
        ],
        {
          root: true
        }
      );
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    applyDefaultTranslationToAll() {
      if (this.defaultValue) {
        for (var i = 0; i < this.translationFields.length; i++) {
          this.translationFields[i].attributes.name = this.defaultValue;
        }
        this.formKey++;
      }
    },
    setDefaultValue(id, value) {
      if (id == 0) {
        this.defaultValue = value;
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    componentTitle: {
      type: String,
      required: true
    }
  }
};
</script>
